import React from 'react';
import '../Root.css';

export default function MissingPage() {
    return (
        <div className="Root">
            <div className={"Root-header"}>
                <p>no page found</p>
            </div>
        </div>
    );
}