import React from 'react';
import './Games.css';

export default function Automagical() {
    return (
        <div className={"Game"}>
            <iframe
                className="ItchEmbed"
                title="Automagical"
                src="https://itch.io/embed-upload/9074715?color=000000"
                allow="fullscreen">
            </iframe>
        </div>
    );
}